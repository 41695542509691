import React from 'react';
import TransferSendFirstStep from './TransferSendFirstStep';
import TransferSendSecondStep from './TransferSendSecondStep';
import TransferResultStep from './TransferResultStep';
import TransferFreeDS from './TransferFreeDS';
import WithTranslator from '../../providers/WithTranslator';
import apiService from '../../services/apiService';
import {RESULT_STATUS as STATUS} from '../../constants';

class TransferSend extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            _3ds: null,
            trId: null,
            step: 1,
            firstFields: null,
            secondFields: null,
            status: STATUS.FORM,
        };
    }

    componentDidMount() {
        const trId = window.location.href.split('?trId=')[1];
        this.part = document.getElementById('transferPart');
        if (trId) {
            this.setState({status: STATUS.WAIT}, () => {
                window.scrollTo({ top: this.part.offsetTop - 88 });
                this.sendQuery(() => apiService.getStatus(decodeURIComponent(trId)));
            });
        }
    }

    goUp = () => window.scrollTo({top: this.part.offsetTop - 88, behavior: 'smooth'});

    onSubmitFirstStep = fields => {
        this.goUp();
        if (fields.deferPayout) {
            const data = this.getData(fields);
            this.setState({status: STATUS.WAIT, firstFields: {...fields}});
            this.sendQuery(() => apiService.startTransfer(data));
        } else {
            this.setState({step: 2, firstFields: {...fields}});
        }
    }

    onSubmitSecondStep = fields => {
        this.goUp();
        const data = this.getData(this.state.firstFields);
        data['to'] = {
            'cardNumber': fields.card.replaceAll(' ', ''),
            'cardHolder': fields.owner,
            'expMonth': +fields.month,
            'expYear': +fields.year,
        }
        this.setState({status: STATUS.WAIT, secondFields: fields});
        this.sendQuery(() => apiService.startTransfer(data));
    }

    onCancelFirstStep = () => {
        this.goUp();
        this.setState({firstFields: null, secondFields: null});
    }
    onCancelSecondStep = () => {
        this.goUp();
        this.setState({step: 1});
    }

    sendQuery = startPromise => startPromise().then(state => this.setState({...state}));

    getData = firstFields => ({
        'deferPayout': firstFields.deferPayout,
        'amount': {
            'value': +firstFields.amount,
            'currency': 'RUB',
        },
        'from': {
            'cardNumber': firstFields.card.replaceAll(' ', ''),
            'cardHolder': firstFields.owner,
            'expMonth': +firstFields.month,
            'expYear': +firstFields.year,
            'cvc': +firstFields.cvv,
        },
        '3ds': {
            'redirectUri': window.location.href,
        },
    });

    onStart = () => {
        this.goUp();
        const state = this.state.status === STATUS.ERROR
            ? {step: 1, status: STATUS.FORM}
            : {step: 1, status: STATUS.FORM, firstFields: null, secondFields: null};
        this.setState(state);
        window.history.pushState({}, null, '/');
    }

    render() {
        return (
            <>
                <form>
                    {this.state.step === 1 && this.state.status === STATUS.FORM
                        ?
                        <TransferSendFirstStep fields={this.state.firstFields}
                                               onSubmit={this.onSubmitFirstStep}
                                               onCancel={this.onCancelFirstStep}/>
                        : this.state.step === 2 && this.state.status === STATUS.FORM &&
                        <TransferSendSecondStep fields={this.state.secondFields}
                                                onSubmit={this.onSubmitSecondStep}
                                                onCancel={this.onCancelSecondStep}/>

                    }

                    {this.state.status !== STATUS.FORM &&
                    <TransferResultStep status={this.state.status} onSubmit={this.onStart}
                                        trId={this.state.trId}/>}
                </form>

                <TransferFreeDS _3ds={this.state._3ds}/>
            </>
        )
    }
}

export default WithTranslator(TransferSend);
