import React from 'react';
import {Fab} from '@material-ui/core';
import HeaderAppBar from './HeaderAppBar';
import HeaderMobileMenu from './HeaderMobileMenu';
import WithTranslator from '../../providers/WithTranslator';

import './Header.scss';
import menuIcon from '../../resources/icons/icon-menu.svg';

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mobileMenuAnchor: null
        }
    }

    componentDidMount() {
        this.parts = {
            mainPart: document.getElementById('mainPart'),
            transferPart: document.getElementById('transferPart'),
            commissionPart: document.getElementById('commissionPart'),
            questionsPart: document.getElementById('questionsPart'),
            contactsPart: document.getElementById('contactsPart'),
        }

        window.addEventListener('scroll', this.onScroll, {passive: true});
    }

    goTo = element => window.scrollTo({top: element.offsetTop - 88, behavior: 'smooth'});

    onScroll = () => {
        const defaultVisible = {
            mainPartVisible: false,
            transferPartVisible: false,
            commissionPartVisible: false,
            questionsPartVisible: false,
            contactsPartVisible: false,
        }
        let visible = {...defaultVisible};
        let maxShift = 0;
        Object.entries(this.parts).forEach(([name, element]) => {
            const position = element.getBoundingClientRect();
            if (position.top >= 0 && position.bottom <= window.innerHeight) {
                maxShift = Infinity;
                visible = {...defaultVisible, [`${name}Visible`]: true};
            } else if (position.top < window.innerHeight && position.bottom >= 0) {
                const height = position.top > 0
                    ? window.innerHeight - position.top
                    : position.bottom;
                if (height >= maxShift) {
                    maxShift = height;
                    visible = {...defaultVisible, [`${name}Visible`]: true};
                }
            }
        });
        this.setState({...visible});
    }

    render() {
        const props = {
            goTo: this.goTo,
            ...this.parts,
            ...this.state,
        }
        return (
            <>
                <HeaderAppBar {...props}/>
                <div className='header-short'>
                    <Fab onClick={e => this.setState({mobileMenuAnchor: e.currentTarget})}>
                        <img src={menuIcon} alt='menu' />
                    </Fab>
                    <HeaderMobileMenu
                        {...props}
                        open={!!this.state.mobileMenuAnchor}
                        onClose={() => this.setState({mobileMenuAnchor: null})}
                    />

                </div>
            </>
        )
    }
}

export default WithTranslator(Header);
