import React from 'react';
import WithTranslator from '../../providers/WithTranslator';

import './Footer.scss';
import logoRu from "../../resources/logo-rus.svg";
import logoEn from "../../resources/logo-eng.svg";

class Footer extends React.Component {
    componentDidMount() {
        this.header = document.getElementById('header');
        this.transferPart = document.getElementById('transferPart');
        this.commissionPart = document.getElementById('commissionPart');
        this.questionsPart = document.getElementById('questionsPart');
        this.contactsPart = document.getElementById('contactsPart');
    }

    goTo = element => window.scrollTo({top: element.offsetTop - 88, behavior: 'smooth'});

    render() {
        const translator = this.props.translator;

        return (
            <>
                <footer className='appbar'>
                    {this.props.language === "ru"  ? (
                        <img src={logoRu} alt='Bank Arvand' className='logo' height="58" />
                    ) : (
                        <img src={logoEn} alt='Bank Arvand' className='logo' height="58"/>
                    )}

                    <div className='menu'>
                        <a onClick={() => this.goTo(this.header)}>{translator('header.link.main')}</a>
                        <a onClick={() => this.goTo(this.transferPart)}>{translator('header.link.transfer')}</a>
                        <a onClick={() => this.goTo(this.commissionPart)}>{translator('header.link.commission')}</a>
                        <a onClick={() => this.goTo(this.questionsPart)}>{translator('header.link.questions')}</a>
                        <a onClick={() => this.goTo(this.contactsPart)}>{translator('header.link.contacts')}</a>
                    </div>
                </footer>
                <div className='_short'>
                    {this.props.language === "ru"  ? (
                        <img src={logoRu} alt='Bank Arvand' className='logo' height="58" />
                    ) : (
                        <img src={logoEn} alt='Bank Arvand' className='logo' height="58"/>
                    )}
                </div>
            </>
        )
    }
}

export default WithTranslator(Footer);
