import React from 'react';
import {AppBar, Button} from '@material-ui/core';
import LanguageControl from './LanguageControl';
import WithTranslator from '../../providers/WithTranslator';

import './Header.scss';
import logoRu from '../../resources/logo-rus.svg';
import logoEn from '../../resources/logo-eng.svg';
import WithTransferMode from "../../providers/WithTransferMode";

class HeaderAppBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            langAnchor: null
        }
    }

    setLanguage = lang => {
        this.setState({langAnchor: null});
        this.props.setLanguage(lang);
    }

    render() {
        const {translator, goTo} = this.props;

        const handleGoToTransfers = () => {
            this.props.setTransferMode(0);
            goTo(this.props.transferPart);
        }

        return (
            <>
                <AppBar position='fixed' className='appbar' id='header'>
                    {this.props.language === "ru"  ? (
                        <img src={logoRu} alt='Bank Arvand' className='logo' height="58" />
                    ) : (
                        <img src={logoEn} alt='Bank Arvand' className='logo' height="58"/>
                    )}
                    <div className='menu'>
                    <a className={this.props.mainPartVisible ? '_active' : ''}
                           onClick={() => goTo(this.props.mainPart)}>
                            {translator('header.link.main')}
                        </a>
                        <a className={this.props.transferPartVisible ? '_active' : ''}
                           onClick={() => goTo(this.props.transferPart)}>
                            {translator('header.link.transfer')}
                        </a>
                        <a className={this.props.commissionPartVisible ? '_active' : ''}
                           onClick={() => goTo(this.props.commissionPart)}>
                            {translator('header.link.commission')}
                        </a>
                        <a className={this.props.questionsPartVisible ? '_active' : ''}
                           onClick={() => goTo(this.props.questionsPart)}>
                            {translator('header.link.questions')}
                        </a>
                        <a className={this.props.contactsPartVisible ? '_active' : ''}
                           onClick={() => goTo(this.props.contactsPart)}>
                            {translator('header.link.contacts')}
                        </a>
                    </div>

                    <LanguageControl/>

                    <Button className='primary' onClick={handleGoToTransfers}>
                        {translator('header.btn.toTransferPart')}
                    </Button>
                </AppBar>
            </>
        )
    }
}

export default WithTransferMode(WithTranslator(HeaderAppBar));
