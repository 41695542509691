import React from 'react';
import {Button, IconButton, Paper} from '@material-ui/core';
import TextInput from '../textInpit/TextInput';
import WithTranslator from '../../providers/WithTranslator';
import apiService from '../../services/apiService';

import './ContactsPart.scss';
import {ReactComponent as IconBack} from '../../resources/icons/icon-back.svg';
import {ReactComponent as Ellipse} from '../../resources/ellipse.svg';
import {ReactComponent as Success} from '../../resources/success.svg';
import {ReactComponent as Warning} from '../../resources/warning.svg';

const DEFAULT_FIELDS = {
    email: '',
    name: '',
    transfer: '',
    message: '',
}

const STATUS = {
    FORM: 'form',
    WAIT: 'wait',
    SUCCESS: 'success',
    ERROR: 'warning'
}

class ContactsPart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: {...DEFAULT_FIELDS},
            errors: {
                email: false,
                name: false,
                transfer: false,
                message: false,
            },
            status: STATUS.FORM,
        }
    }

    updateFields = (name, value) =>
        this.setState({fields: {...this.state.fields, [name]: value}, errors: {...this.state.errors, [name]: false}});

    onEmailChange = e => {
        let email = e.currentTarget.value;
        if (/^[\w-\.]*@?[\w-\.]*$/.test(email))
            this.updateFields('email', email);
    }

    onSubmit = () => {
        const errors = {
            email: !/^[\w-\.]+@([\w-]+\.)+[\w-]+$/.test(this.state.fields.email),
            name: !this.state.fields.name,
            transfer: !this.state.fields.transfer,
            message: !this.state.fields.message,
        };
        if (Object.values(errors).some(i => !!i)) {
            this.setState({errors: {...errors}});
        } else {
            this.setState({status: STATUS.WAIT});
            const {name, email, transfer, message} = this.state.fields;
            apiService.sendFeedback({
                replyTo: `${name} <${email}>`,
                subject: this.props.translator('contactsPart.email.subject')(transfer),
                message: this.props.translator('contactsPart.email.message')(name, email, transfer, message)
            }).then(() => this.setState({status: STATUS.SUCCESS}))
                .catch(() => this.setState({status: STATUS.ERROR}))
        }
    }

    onStart = () => this.setState({status: STATUS.FORM, fields: {...DEFAULT_FIELDS}});

    render() {
        const translator = this.props.translator;
        return (
            <div className='contacts-part' id='contactsPart'>
                <span className='h2'>{translator('contactsPart.title')}</span>
                <div className='contacts-part__inner'>
                    <span className='child requisites'>
                        {/* <span className='h3'>{translator('contactsPart.text1')}</span> */}
                        <span className='h3 margin-bottom'>{translator('contactsPart.text2')}</span>
                        <span className=''>{translator('contactsPart.text3')}</span>
                        <span className=''>{translator('contactsPart.text4')}</span>
                        {this.props.language === 'ru'
                            ?
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d760.8741076833986!2d69.62295726294177!3d40.28693512185189!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38b1b300c3e4af47%3A0x901f0c4adfb0db95!2z0JfQkNCeINCR0LDQvdC6INCQ0YDQstCw0L3QtA!5e0!3m2!1sru!2sby!4v1708082338116!5m2!1sru!2sby"
                                frameBorder="0" className="map" allowFullScreen="" aria-hidden="false"></iframe>
                            :
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d760.8741079293819!2d69.6229573!3d40.2869351!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38b1b300c3e4af47%3A0x901f0c4adfb0db95!2sZao%20Bank%20Arvand!5e0!3m2!1sen!2sby!4v1708082400826!5m2!1sen!2sby"
                                frameBorder="0" className="map" allowFullScreen="" aria-hidden="false"></iframe>
                        }
                    </span>
                    <form className='child'>
                        <span className='h3'>{translator('contactsPart.form.title')}</span>


                        {this.state.status === STATUS.FORM
                            ?
                            <>
                                <Paper>
                                    <div className='inline-fields margin-bottom'>
                                        <TextInput label={translator('contactsPart.form.email')} className='email'
                                                   type='email'
                                                   value={this.state.fields.email} onChange={this.onEmailChange}
                                                   error={this.state.errors.email}/>
                                        <TextInput label={translator('contactsPart.form.name')}
                                                   value={this.state.fields.name} error={this.state.errors.name}
                                                   onChange={e => this.updateFields('name', e.currentTarget.value)}/>
                                    </div>
                                    <TextInput label={translator('contactsPart.form.transaction')}
                                               className='margin-bottom'
                                               value={this.state.fields.transfer} error={this.state.errors.transfer}
                                               onChange={e => this.updateFields('transfer', e.currentTarget.value)}/>
                                    <TextInput label={translator('contactsPart.form.message')}
                                               className='message margin-bottom' multiline maxRows={6} minRows={6}
                                               value={this.state.fields.message} error={this.state.errors.message}
                                               onChange={e => this.updateFields('message', e.currentTarget.value)}/>
                                </Paper>

                                <Button className='primary _large' onClick={this.onSubmit}
                                        disabled={!Object.values(this.state.fields).every(f => !!f) ||
                                        Object.values(this.state.errors).some(e => !!e)}>
                                    {translator('contactsPart.form.btn.submit')}
                                </Button>
                            </>
                            : this.state.status === STATUS.WAIT
                                ?
                                <Paper className='success-part'>
                                    <div/>
                                    <div className='progress'>
                                        <Ellipse/>
                                    </div>
                                    <div/>
                                </Paper>
                                : this.state.status === STATUS.SUCCESS
                                    ?
                                    <Paper className='success-part'>
                                        <IconButton onClick={this.onStart}>
                                            <IconBack/>
                                        </IconButton>
                                        <Success/>
                                        <div>
                                    <span className='h3'>
                                        {translator(`contactsPart.success.title`)}
                                    </span>
                                            <span className='description'>
                                            {this.state.fields.name}
                                                {translator(`contactsPart.success.description`)}
                                                {this.state.fields.email}
                                    </span>
                                        </div>
                                    </Paper>
                                    :
                                    <Paper className='success-part'>
                                        <IconButton onClick={this.onStart}>
                                            <IconBack/>
                                        </IconButton>
                                        <Warning/>
                                        <span className='h3'>
                                            {translator('contactsPart.warning.title')}
                                        </span>
                                    </Paper>


                        }
                    </form>
                </div>
            </div>
        )
    }
}

export default WithTranslator(ContactsPart);
